import {
  eventService,
  settingService,
  notificationService,
} from "@/core/services";

export default {
  data() {
    return {
      showProgress: false,
    };
  },
  watch: {
    model(newVal, _oldVal) {
      this.populateForm(newVal);
    },
  },
  mounted() {
    if (this.eventType && this.eventType.CANCEL) {
      eventService.listen(this.eventType.CANCEL, this.resetFormModel);
    }

    if (this.eventType && this.eventType.SAVE) {
      eventService.listen(this.eventType.SAVE, this.save);
    }
  },
  destroyed() {
    if (this.eventType && this.eventType.CANCEL) {
      eventService.remove(this.eventType.CANCEL, this.resetFormModel);
    }
    if (this.eventType && this.eventType.SAVE) {
      eventService.remove(this.eventType.SAVE, this.save);
    }
  },
  methods: {
    resetFormModel() {
      if (this.model && this.formGroup && this.formGroup.fields) {
        this.formGroup.fields.forEach((prop) => {
          this.formGroup[prop].value = this.model[prop];
        });
      }
    },
    closeEdit() {
      if (this.settingType) {
        settingService.toggleEdit(this.settingType, false);
      }
    },
    populateForm(data) {
      if (this.formGroup) {
        this.formGroup.populate(data);
      }
    },
    toggleProgress() {
      this.showProgress = !this.showProgress;
    },
    save() {
      const form = this.formGroup.value;
      const payload = { type: this.settingType, data: form };

      this.toggleProgress();
      settingService.saveDetails(payload).finally(() => {
        notificationService.success("Settings has been updated successfully");
        this.toggleProgress();
        this.closeEdit();
      });
    },
  },
};
