<template>
  <div class="file-upload-wrapper">
    <div class="head-sec">
      <h3>Safety Cards</h3>
    </div>
    <div class="file-drag-drop">
      <div class="uploader">
        <input
          id="file-upload"
          ref="multiUploadInput"
          type="file"
          name="fileUpload"
          multiple
          accept="image/*"
          class="multi-file-input"
          @change="onChangeFile"
        />
        <label id="file-drag" for="file-upload">
          <div id="start">
            <i class="fa fa-download" aria-hidden="true"></i>
            <div>Select a file</div>
            <div id="notimage" class="hidden">Please select an image</div>
            <span id="multi-file-upload-btn" class="btn btn-primary">
              Select a file
            </span>
          </div>
        </label>
      </div>
      <div
        v-if="files.length || filesUploaded.length"
        class="progress-container"
      >
        <div
          v-for="item in filesUploaded"
          :key="item.id"
          class="file-progress-info"
        >
          <!-- <i class="fas fa-file-alt text-success"></i> -->

          <div class="file-progress-info_details">
            <i class="fas fa-file-alt text-success"></i>
          </div>
          <div class="file-progress-info_loading">
            <base-progress
              :animated="false"
              type="success"
              :value="item.progress"
              :label="item.name"
              :show-label="true"
            ></base-progress>
          </div>
        </div>
        <div
          v-for="(item, index) in files"
          :key="index"
          class="file-progress-info"
        >
          <div class="file-progress-info_details">
            <i class="fas fa-file-alt text-success"></i>
          </div>
          <div class="file-progress-info_loading">
            <base-progress
              :animated="true"
              type="success"
              :value="item.progress"
              :label="item.name"
              :show-label="true"
            ></base-progress>
          </div>
        </div>
      </div>
      <div class="uploaded-img-heading">
        <h3>Uploaded Images</h3>
      </div>
      <div class="img-previewer" :class="{ 'no-img-uploaded': noImgFound }">
        <h2 v-if="noImgFound" class="text-uppercase font-weight-bolder">
          No Images Found
        </h2>
        <div
          v-for="fileItem in selectedDocs"
          :key="fileItem.id"
          class="img-reader"
        >
          <i
            class="fas fa-times text-danger"
            @click="onDeleteFile(fileItem)"
          ></i>

          <img :src="fileItem.file_name" alt="fileItem.file_name" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Helper } from "@/core/utils";
import {
  settingService,
  notificationService,
  confirmDialogService,
} from "@/core/services";
export default {
  name: "MultiUpload",
  props: {
    label: {
      type: String,
      default: "",
    },
    defaultText: {
      type: String,
      default: "No File Attach",
    },
    hideRemove: {
      type: Boolean,
      default: false,
    },
    uploadCallback: {
      type: Function,
      default: null,
    },
    selectedDocs: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      collapsed: false,
      // docLoader: false,
      file: null,
      files: [],
      filesUploaded: [],
      fileResponse: null,
      uploaded: { src: null, file: "" },
    };
  },
  computed: {
    fileElement() {
      return this.$refs.multiUploadInput;
    },
    hasDowload() {
      return this.download;
    },
    noImgFound() {
      return this.selectedDocs.length == 0;
    },
  },
  mounted() {},
  methods: {
    onChangeFile(event) {
      let fileName;
      const files = event.target.files.length > 0 ? event.target.files : [];
      /* if (this.file) {
        this.onUploadFile();
      } */
      for (let file of files) {
        if (file) {
          fileName = Helper.fileNameFromUrl(file.name);

          if (!fileName) {
            fileName = file.name;
          }

          this.files.push({
            file: file,
            name: fileName,
            progress: 0,
            id: Helper.uid(),
          });
        }
      }
      this.files.forEach((fileModel) => {
        this.onUploadFile(fileModel);
      });
    },
    onUploadFile(fileModel) {
      if (this.uploadCallback) {
        this.uploadCallback(fileModel.file, (progressEvent) => {
          this.updateProgress(progressEvent, fileModel);
        })
          .then((_settingDetails) => {
            console.log("_settingDetails", _settingDetails);
          })
          .finally(() => {
            this.resetFile();
          });
      }
    },
    async onDeleteFile(fileItem) {
      console.log(fileItem);

      confirmDialogService.open(
        "Are you sure you want to delete this Image?",
        () => {
          console.log(fileItem, "original_name");

          settingService
            .removeDoc(fileItem)
            .then((res) => {
              notificationService.success(res.message);
            })
            .finally(() => (this.loading.container = false));
        }
      );

      /* const docID =
        (this.fileResponse && this.fileResponse.id) ||
        (this.selectedDoc && this.selectedDoc.id);
      if (!docID) {
        return;
      }
      this.profileLoader = true;
      return await axios
        .delete(
          `/api/employee/document/${docID}?type=attachment&emp_id=${
            this.employeeID || null
          }`
        )
        .then((res) => {
          this.removeFile();
        })
        .catch((error) => {
          console.log("error");
          console.log(error);
        })
        .finally(() => {
          this.profileLoader = false;
        }); */
    },
    resetFile() {
      this.fileElement.value = "";
      this.filesUploaded = [...this.filesUploaded, ...this.files];
      this.files = [];
    },
    removeFile() {
      this.uploaded.file = null;
      this.fileResponse = null;
      this.selectedDoc.file_name = null;
      this.selectedDoc.original_name = null;
      this.resetFile();
    },
    emitDocumentData() {},
    updateProgress(progressEvent, fileModel) {
      setTimeout(() => {
        fileModel.progress = parseInt(
          Math.round((progressEvent.loaded / progressEvent.total) * 100)
        );
      }, 1000);
    },
  },
};
</script>

<style lang="scss" scoped>
$theme: #454cad;
$dark-text: #5f6982;

.file-upload-wrapper {
  display: flex;
  flex-direction: column;
  .head-sec {
    h3 {
      font-weight: 700;
      text-transform: uppercase;
      text-align: center;
      font-size: 1.2em;
    }
  }
  .file-drag-drop {
    width: 80%;
    margin: auto;
    .uploader {
      display: flex;
      width: 100%;
      height: 100%;

      label {
        float: left;
        clear: both;
        width: 100%;
        padding: 2rem 1.5rem;
        text-align: center;
        background: #f1f1f1;
        border-radius: 7px;
        border: 2px dashed #92b0b3;
        transition: all 0.2s ease;
        user-select: none;

        &:hover {
          border-color: $theme;
        }
        &.hover {
          border: 3px solid $theme;
          box-shadow: inset 0 0 0 6px #eee;

          #start {
            i.fa {
              transform: scale(0.8);
              opacity: 0.3;
            }
          }
        }
      }
      #start {
        float: left;
        clear: both;
        width: 100%;
        &.hidden {
          display: none;
        }
        i.fa {
          font-size: 50px;
          margin-bottom: 1rem;
          transition: all 0.2s ease-in-out;
        }
        #multi-file-upload-btn {
          margin-top: 1.1em;
        }
      }
      #file-image {
        display: inline;
        margin: 0 auto 0.5rem auto;
        width: auto;
        height: auto;
        max-width: 180px;
        &.hidden {
          display: none;
        }
      }
      #notimage {
        display: block;
        float: left;
        clear: both;
        width: 100%;
        &.hidden {
          display: none;
        }
      }
      input[type="file"] {
        display: none;
      }
    }
    .progress-container {
      .file-progress-info {
        margin-top: 1em;
        display: flex;
        &_details {
          flex-basis: 6%;
          i {
            font-size: 2.5em;
          }
        }
        &_loading {
          flex-basis: 100%;
          ::v-deep {
            .progress-label {
              span {
                font-size: 0.825rem;
                text-transform: none;
              }
            }
          }
        }
      }
    }
    .uploaded-img-heading {
      margin-top: 1em;
      border-bottom: 1px solid #746d6d;
      h3 {
        font-weight: 700;
        text-transform: uppercase;
      }
    }
    .img-previewer {
      border: 1px solid #9f9898;
      padding: 1em;
      margin-top: 0.2em;
      display: flex;
      flex-wrap: wrap;
      height: 380px;
      overflow-y: auto;
      &.no-img-uploaded {
        justify-content: center;
        align-items: center;
      }
      .img-reader {
        width: 18%;
        border: 1px solid #d3dce7;
        text-align: center;
        max-height: 150px;
        margin-left: 0.7em;
        margin-bottom: 1em;
        img {
          height: 100%;
          width: 100%;
        }
      }
    }
    .text-danger {
      cursor: pointer;
    }
  }
}
</style>
