<template>
  <section>
    <validation-observer ref="formValidator">
      <div class="row">
        <div class="col-md-4 col-sm-6">
          <label-content
            v-model="formGroup.referral_flight_hour.value"
            label="Referral flight hour"
            :editable="editable"
            suffix="token"
            :rules="formGroup.referral_flight_hour.rule"
            type="number"
          ></label-content>
        </div>
        <div class="col-md-4 col-sm-6">
          <label-content
            v-model="formGroup.days_referral_generate_tokens.value"
            label="Days referral generate tokens"
            :editable="editable"
            suffix="days"
            :rules="formGroup.days_referral_generate_tokens.rule"
            type="number"
          ></label-content>
        </div>
        <div class="col-md-4 col-sm-6">
          <label-content
            v-model="formGroup.own_flight_hours.value"
            label="Own flight hours"
            :editable="editable"
            suffix="token"
            :rules="formGroup.own_flight_hours.rule"
            type="number"
          ></label-content>
        </div>
      </div>
    </validation-observer>
    <div class="token-sec">
      <h3>Refund policy:</h3>
      <div class="refund-policy">
        <div class="refund-policy__hrs refund-policy-field">
          <span class="type">More than</span>
          <span class="type-value">3 days before</span>
        </div>
        <div class="refund-policy__cutoff refund-policy-field">
          <span class="type font-weight-bold">Before flight</span>
          <span class="type-value">100%</span>
        </div>
        <div class="refund-policy__apply refund-policy-field">
          <!-- <div class="apply-sec">
            <span>Cash</span>
            <i class="ni ni-check-bold text-success"></i>
          </div> -->
          <div class="apply-sec">
            <span>Tokens</span>
            <!-- <i class="ni ni-fat-remove text-danger"></i> -->
          </div>
        </div>
      </div>
      <div class="refund-policy">
        <div class="refund-policy__hrs refund-policy-field">
          <span class="type">1-3</span>
          <span class="type-value">Days</span>
        </div>
        <div class="refund-policy__cutoff refund-policy-field">
          <span class="type font-weight-bold">Before flight</span>
          <span class="type-value">80%</span>
        </div>
        <div class="refund-policy__apply refund-policy-field">
          <div class="apply-sec">
            <span>Tokens</span>
            <!-- <i class="ni ni-fat-remove text-danger"></i> -->
          </div>
        </div>
      </div>
      <div class="refund-policy">
        <div class="refund-policy__hrs refund-policy-field">
          <span class="type">0-24</span>
          <span class="type-value">Hrs</span>
        </div>
        <div class="refund-policy__cutoff refund-policy-field">
          <span class="type font-weight-bold">Before flight</span>
          <span class="type-value">50%</span>
        </div>
        <div class="refund-policy__apply refund-policy-field">
          <div class="apply-sec">
            <span>Tokens</span>
            <!-- <i class="ni ni-fat-remove text-danger"></i> -->
          </div>
        </div>
      </div>
    </div>
    <div class="token-sec">
      <h3>Token Campaign:</h3>
      <div class="campaign">
        <div class="campaign__col">
          <div class="campaign-content">
            <div class="campaign-content__date">
              <label for="">Campaign Period</label>
              <div class="input-container">
                <div class="input-container__control">
                  <label-content
                    v-model="formGroup.campaign_start_date.value"
                    :editable="editable"
                    input-type="date"
                    placeholder="From Day"
                  ></label-content>
                </div>
                <div class="input-container__control">
                  <label-content
                    v-model="formGroup.campaign_end_date.value"
                    :editable="editable"
                    input-type="date"
                    placeholder="To Day"
                  ></label-content>
                </div>
              </div>
            </div>
            <div class="campaign-content__time">
              <label for="">Campaign Time</label>
              <div class="input-container">
                <div class="input-container__control">
                  <label-content
                    v-model="formGroup.campaign_start_time.value"
                    :editable="editable"
                    input-type="time"
                    placeholder="From Time"
                  ></label-content>
                </div>
                <div class="input-container__control">
                  <label-content
                    v-model="formGroup.campaign_end_time.value"
                    :editable="editable"
                    input-type="time"
                    placeholder="To Time"
                  ></label-content>
                  <!-- <base-datepicker
                  ref="datepicker"
                  :readonly="false"
                  mode="range"
                  v-model="formGroup.to_time.value"
                  :value="formGroup.to_time"
                  no-calendar="true"
                  date-format="H:i"
                  label="To Time"
                  placeholder="Select Date"
                ></base-datepicker> -->
                </div>
              </div>
            </div>

            <!-- <div class="campaign-txt campaign-txt--narrow">08.00</div> -->

            <!-- <div class="campaign-txt campaign-txt--narrow">19.30</div> -->
          </div>
        </div>
      </div>
      <!-- <div class="campaign">
        <div class="campaign__col">
          <div class="campaign-content">
            <div class="campaign-txt">0.5</div>
            <span class="campaign-txt-val">Token</span>
          </div>
        </div>
      </div> -->
    </div>
    <progress-loader :show="showProgress" :no-wrap="true"></progress-loader>
  </section>
</template>

<script>
import { notificationService, settingService } from "@/core/services";
import { FormGroup, TokenGeneration } from "@/core/models";
import LabelContent from "@/views/common/LabelContent.vue";
import { SETTING, SETTING_EVENT } from "@/core/enums";
import SettingMixin from "@/core/mixins/setting.mixin";

export default {
  name: "TokenGeneration",
  components: { LabelContent },
  mixins: [SettingMixin],
  props: {
    model: {
      type: Object,
      default: () => {
        return new TokenGeneration();
      },
    },
  },
  data() {
    return {
      formGroup: new FormGroup({
        referral_flight_hour: [null, { required: true }],
        days_referral_generate_tokens: [null, { required: true }],
        own_flight_hours: [null, { required: true }],
        date: [null],
        campaign_start_time: [null],
        campaign_end_time: [null],
        campaign_start_date: [null],
        campaign_end_date: [null],
      }),
      eventType: SETTING_EVENT.TOKEN,
      settingType: SETTING.TOKEN,
      campaignDuration: null,
    };
  },
  computed: {
    editable() {
      return settingService.isEditable(SETTING.TOKEN);
    },
    campaignDate: {
      get() {
        /*  console.log(this.formGroup.campaign_end_date);
        if (
          this.formGroup.campaign_end_date.value &&
          this.formGroup.campaign_start_date.value
        ) {
          return [
            this.formGroup.campaign_start_date.value,
            this.formGroup.campaign_end_date.value,
          ];
        } */
        return this.campaignDuration;
      },
      set(value) {
        this.campaignDuration = value;
        console.log(this.formGroup.campaign_start_date.value);
        if (value && value.length) {
          this.formGroup.campaign_start_date.value = value[0];
          this.formGroup.campaign_end_date.value = value[1];
        }
        console.log("value", value);
        this.$emit("input", value);
      },
    },
  },
  watch: {
    model: {
      immediate: true,
      handler(newVal, _oldVal) {
        console.log("newValnewVal", newVal);
      },
    },
  },
  methods: {
    async save() {
      const validate = await this.$refs.formValidator.validate();
      if (validate) {
        const form = { ...this.formGroup.value };
        console.log("this.formGroup.value", this.formGroup.campaign_start_date);
        if (form.date) {
          const campaignDate = form.date.split(" to ");
          form.campaign_start_date = campaignDate[0];
          form.campaign_end_date = campaignDate[1];

          this.formGroup.campaign_start_date.value = form.campaign_start_date;
          this.formGroup.campaign_end_date.value = form.campaign_end_date;

          delete form.date;
        }
        const payload = { type: this.settingType, data: form };

        this.toggleProgress();
        settingService.saveDetails(payload).finally(() => {
          notificationService.success("Settings has been updated successfully");
          this.toggleProgress();
          this.closeEdit();
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .token-sec {
    margin-bottom: 1.5em;
    h3 {
      border-bottom: 1px solid #dfdfdf;
      font-weight: 700;
      text-transform: capitalize;
      font-size: 0.98em;
      margin-bottom: 1em;
    }
    .refund-policy {
      display: flex;
      font-size: 0.865em;
      margin-bottom: 0.5em;
      &-field {
        padding-right: 4em;
        &:nth-last-of-type(1) {
          padding-right: 0;
        }
        .type {
          padding-right: 0.5em;
        }
      }
      &__hrs {
        flex-basis: 18%;
      }
      &__cutoff {
        flex-basis: 18%;
      }
      &__apply {
        display: flex;
        padding-left: 3em;
        .apply-sec {
          display: flex;
          align-items: center;
          padding-right: 0.8em;
          span {
            font-weight: 600;
          }
          i {
            padding-left: 5px;
            font-size: 1.1em;
          }
        }
      }
    }
    .campaign {
      display: flex;
      font-size: 0.865em;
      margin-bottom: 0.5em;
      &__col {
        width: 100%;
        padding-right: 3em;
        &:nth-last-of-type(1) {
          padding-right: 0;
        }
        .campaign-content {
          display: flex;
          margin-top: 1em;
          .label-content {
            margin-bottom: 0;
          }

          .control {
            width: 100%;
          }
          &__date {
            flex-basis: 30%;
            display: flex;
            flex-direction: column;
            margin-right: 1em;
            .input-container {
              display: flex;
              &__control {
                width: 100%;
                .label-content__value {
                  max-width: 95%;
                  flex-basis: 100%;
                }
              }
            }
          }
          &__time {
            display: flex;
            flex-direction: column;
            margin-right: 1em;
            .input-container {
              display: flex;

              &__control {
                flex-basis: 20%;
                margin-right: 0.5em;
                .label-content__value {
                  max-width: 100%;
                  flex-basis: 100%;
                }
              }
            }
          }
          .campaign-txt {
            padding-right: 1em;
            padding-left: 1em;
            &--narrow {
              padding-right: 0.6em;
              padding-left: 0.6em;
            }
            &:nth-of-type(1) {
              padding-left: 0;
            }
            &:nth-last-of-type(1) {
              padding-right: 0;
            }
          }
          .campaign-txt-val {
            padding-left: 0.5em !important;
          }
          .divider {
            padding: 0 0.2em;
          }
          .dash {
            &::after {
              display: block;
              text-align: center;
              font-size: 0.7em;
              content: "—";
              position: relative;
              top: 3px;
            }
          }
        }
      }
    }
  }
}
</style>
