<template>
  <section>
    <validation-observer ref="formValidator">
      <div class="row">
        <div class="col-md-4 col-sm-6">
          <label-content
            v-model="formGroup.block_time.value"
            label="Block Time"
            suffix="min"
            :editable="editable"
            :rules="formGroup.block_time.rule"
            type="number"
          ></label-content>
        </div>
        <div class="col-md-4 col-sm-6">
          <label-content
            v-model="formGroup.speed.value"
            label="Speed"
            suffix="Knots"
            format-type="decimal"
            :editable="editable"
            :rules="formGroup.speed.rule"
            type="number"
          ></label-content>
        </div>
        <div class="col-md-4 col-sm-6">
          <label-content
            v-model="formGroup.price_per_minute.value"
            label="Price per minute"
            format-type="price"
            :editable="editable"
            :rules="formGroup.price_per_minute.rule"
            type="number"
          ></label-content>
        </div>
        <div class="col-md-4 col-sm-6">
          <label-content
            v-model="formGroup.ferry_discount.value"
            label="Ferry Discount"
            format-type="percent"
            :editable="editable"
            :rules="formGroup.ferry_discount.rule"
            type="number"
          ></label-content>
        </div>
        <div class="col-md-4 col-sm-6">
          <label-content
            v-model="formGroup.landing_fees.value"
            label="Landing Fees"
            format-type="price"
            :editable="editable"
            :rules="formGroup.landing_fees.rule"
            type="number"
          ></label-content>
        </div>
        <div class="col-md-4 col-sm-6">
          <label-content
            v-model="formGroup.waiting_time_free.value"
            label="Waiting Time Free"
            suffix="min"
            :editable="editable"
            :rules="formGroup.waiting_time_free.rule"
            type="number"
          ></label-content>
        </div>
        <div class="col-md-4 col-sm-6">
          <label-content
            v-model="formGroup.waiting_price_per_hour.value"
            format-type="price"
            label="Waiting Price/Min"
            :editable="editable"
            :rules="formGroup.waiting_price_per_hour.rule"
            type="number"
          ></label-content>
        </div>
        <div class="col-md-4 col-sm-6">
          <label-content
            v-model="formGroup.waiting_price_24_hour.value"
            label="Waiting Time 24h"
            format-type="price"
            :editable="editable"
            :rules="formGroup.waiting_price_24_hour.rule"
            type="number"
          ></label-content>
        </div>
      </div>
    </validation-observer>

    <progress-loader :show="showProgress" :no-wrap="true"></progress-loader>
  </section>
</template>

<script>
import {
  /* eventService, */ notificationService,
  settingService,
} from "@/core/services";
import { FormGroup, PricingFormula } from "@/core/models";
import LabelContent from "@/views/common/LabelContent.vue";
import { SETTING, SETTING_EVENT } from "@/core/enums";
import SettingMixin from "@/core/mixins/setting.mixin";

export default {
  name: "Pricing",
  components: { LabelContent },
  mixins: [SettingMixin],
  props: {
    model: {
      type: Object,
      default: () => {
        return new PricingFormula();
      },
    },
  },
  data() {
    return {
      formGroup: new FormGroup({
        block_time: [null, { required: true }],
        speed: [null, { required: true }],
        price_per_minute: [null, { required: true }],
        ferry_discount: [null, { required: true }],
        landing_fees: [null, { required: true }],
        waiting_time_free: [null, { required: true }],
        waiting_price_per_hour: [null, { required: true }],
        waiting_price_24_hour: [null, { required: true }],
      }),
      eventType: SETTING_EVENT.PRICING,
      settingType: SETTING.PRICING,
    };
  },
  computed: {
    editable() {
      return settingService.isEditable(SETTING.PRICING);
    },
  },
  methods: {
    async save() {
      const validate = await this.$refs.formValidator.validate();
      if (validate) {
        const form = this.formGroup.value;
        const payload = { type: this.settingType, data: form };

        this.toggleProgress();
        settingService.saveDetails(payload).finally(() => {
          notificationService.success("Settings has been updated successfully");
          this.toggleProgress();
          this.closeEdit();
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
