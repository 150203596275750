<template>
  <section>
    <validation-observer ref="formValidator">
      <div class="row">
        <div class="col-md-5 col-sm-6">
          <label-content
            v-model="formGroup.amount_of_invitations_when_joining.value"
            label="Amount of invitations when joining"
            format-type="pad2"
            :editable="editable"
            :rules="formGroup.amount_of_invitations_when_joining.rule"
            type="number"
          ></label-content>
        </div>
        <div class="col-md-5 col-sm-6">
          <label-content
            v-model="
              formGroup
                .amount_of_additional_invitations_if_invite_become_customer
                .value
            "
            label="Amount of additional invitations if invite becomes customer"
            format-type="pad2"
            :editable="editable"
            :rules="
              formGroup
                .amount_of_additional_invitations_if_invite_become_customer.rule
            "
            type="number"
          ></label-content>
        </div>
        <div class="col-md-5 col-sm-6">
          <label-content
            v-model="formGroup.max_account_of_shadow_accounts.value"
            label="Max amount of shadow accounts"
            format-type="pad2"
            :editable="editable"
            :rules="formGroup.max_account_of_shadow_accounts.rule"
            type="digits_field"
          ></label-content>
        </div>
        <div class="col-md-5 col-sm-6">
          <label-content
            v-model="formGroup.invite_message.value"
            label="Invite Message"
            :editable="editable"
            :rules="formGroup.invite_message.rule"
          ></label-content>
        </div>
        <div class="col-md-5 col-sm-6">
          <label-content
            v-model="formGroup.shadow_invite_message.value"
            label="Shadow Invite Message"
            :editable="editable"
            :rules="formGroup.shadow_invite_message.rule"
          ></label-content>
        </div>
      </div>
    </validation-observer>
    <progress-loader :show="showProgress" :no-wrap="true"></progress-loader>
  </section>
</template>

<script>
import { settingService, notificationService } from "@/core/services";
import { FormGroup, Invitation } from "@/core/models";
import LabelContent from "@/views/common/LabelContent.vue";
import { SETTING, SETTING_EVENT } from "@/core/enums";
import SettingMixin from "@/core/mixins/setting.mixin";

export default {
  name: "BookingMargin",
  components: { LabelContent },
  mixins: [SettingMixin],
  props: {
    model: {
      type: Object,
      default: () => {
        return new Invitation();
      },
    },
  },
  data() {
    return {
      formGroup: new FormGroup({
        amount_of_invitations_when_joining: [
          this.model.amount_of_invitations_when_joining,
          { numeric: true, required: true },
        ],
        amount_of_additional_invitations_if_invite_become_customer: [
          this.model.amount_of_additional_invitations_if_invite_become_customer,
          { numeric: true, required: true },
        ],
        max_account_of_shadow_accounts: [
          this.model.max_account_of_shadow_accounts,
          { numeric: true, required: true },
        ],
        invite_message: [this.model.invite_message, { required: true }],
        shadow_invite_message: [
          this.model.shadow_invite_message,
          { required: true },
        ],
      }),
      eventType: SETTING_EVENT.INVITATION,
      settingType: SETTING.INVITATION,
    };
  },
  computed: {
    editable() {
      return settingService.isEditable(SETTING.INVITATION);
    },
  },
  methods: {
    async save() {
      const validate = await this.$refs.formValidator.validate();
      if (validate) {
        const form = this.formGroup.value;
        const payload = { type: this.settingType, data: form };

        this.toggleProgress();
        settingService.saveDetails(payload).finally(() => {
          notificationService.success("Settings has been updated successfully");
          this.toggleProgress();
          this.closeEdit();
        });
      }
    },
  },
};
</script>
