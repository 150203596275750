<template>
  <div>
    <base-header class="pb-6">
      <b-row align-v="center" class="py-4">
        <b-col lg="6" cols="7">
          <h6 class="h2 text-white d-inline-block mb-0 breadcrum-heading">
            Settings
          </h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </b-col>
      </b-row>
    </base-header>
    <b-container fluid class="mt--6">
      <b-card no-body class="mb-2">
        <b-card-body>
          <b-row>
            <b-col cols="12">
              <detail-panel>
                <template #header>
                  <title-block
                    title="Pricing Formula"
                    :setting-type="SETTING.PRICING"
                    mode="PRICING"
                  ></title-block>
                </template>
                <template #content>
                  <pricing :model="details[SETTING.PRICING]"></pricing>
                </template>
              </detail-panel>
            </b-col>
            <b-col cols="12">
              <detail-panel>
                <template #header>
                  <title-block
                    title="Booking Margins"
                    :setting-type="SETTING.BOOKING"
                    mode="BOOKING"
                  ></title-block>
                </template>
                <template #content>
                  <booking-margin
                    :model="details[SETTING.BOOKING]"
                  ></booking-margin>
                </template>
              </detail-panel>
            </b-col>
            <b-col cols="12">
              <detail-panel>
                <template #header>
                  <title-block
                    title="Invitations"
                    :setting-type="SETTING.INVITATION"
                    mode="INVITATION"
                  ></title-block>
                </template>
                <template #content>
                  <invitation :model="details[SETTING.INVITATION]"></invitation>
                </template>
              </detail-panel>
            </b-col>
            <b-col cols="12">
              <detail-panel>
                <template #header>
                  <title-block
                    title="Token Generation"
                    :setting-type="SETTING.TOKEN"
                    mode="TOKEN"
                  ></title-block>
                </template>
                <template #content>
                  <token-generation
                    :model="details[SETTING.TOKEN]"
                  ></token-generation>
                </template>
              </detail-panel>
            </b-col>
            <b-col cols="12">
              <detail-panel>
                <template #header>
                  <title-block
                    title="Terms And Condition"
                    :setting-type="SETTING.TERMSANDCONDITION"
                    mode="TermsAndCondiition"
                    editoption="false"
                  ></title-block>
                </template>
                <template #content>
                  <terms-and-condiition
                    :model="details[SETTING.TEXT]"
                  ></terms-and-condiition>
                </template>
              </detail-panel>
            </b-col>
            <b-col cols="12">
              <detail-panel>
                <template #header>
                  <title-block
                    title="Policy"
                    :setting-type="SETTING.POLICY"
                    mode="Policy"
                    :model="details[SETTING.TEXT]"
                    editoption="false"
                  ></title-block>
                </template>
                <template #content>
                  <policy :model="details[SETTING.POLICY]"></policy>
                </template>
              </detail-panel>
            </b-col>

            <b-col cols="12">
              <detail-panel>
                <template #header>
                  <title-block
                    title="Information Popup"
                    :setting-type="SETTING.INFORMATIONPOPUP"
                    mode="INFORMATIONPOPUP"
                  ></title-block>
                </template>
                <template #content>
                  <information
                    :model="details[SETTING.INFORMATIONPOPUP]"
                  ></information>
                </template>
              </detail-panel>
            </b-col>

            <b-col cols="12">
              <detail-panel>
                <template #header>FAQ</template>
                <template #content>
                  <faqs></faqs>
                </template>
              </detail-panel>
            </b-col>
            <b-col cols="12">
              <detail-panel>
                <template #header>Uploads</template>
                <template #content>
                  <doc-upload :model="details[SETTING.OTHER]"></doc-upload>
                </template>
              </detail-panel>
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>
    </b-container>
  </div>
</template>

<script>
import { RouteBreadCrumb } from "@/components";

import DetailPanel from "@views/common/DetailPanel.vue";
import TitleBlock from "./components/TitleBlock.vue";
import Pricing from "./components/Pricing.vue";
import TermsAndCondiition from "./components/TermsAndCondition.vue";
import Policy from "./components/Policy.vue";
import Information from "./components/Information.vue";
import BookingMargin from "./components/BookingMargin.vue";
import Invitation from "./components/Invitation.vue";
import TokenGeneration from "./components/TokenGeneration.vue";
import Faqs from "./components/Faqs.vue";
import DocUpload from "./components/DocUpload.vue";

import { SETTING } from "@/core/enums";
import { settingService } from "@/core/services";
import { mapGetters } from "vuex";

export default {
  name: "Settings",
  components: {
    RouteBreadCrumb,
    DetailPanel,
    TitleBlock,
    Pricing,
    TermsAndCondiition,
    Policy,
    Information,
    Faqs,
    BookingMargin,
    Invitation,
    TokenGeneration,
    DocUpload,
  },
  data() {
    return {
      SETTING,
    };
  },
  computed: {
    ...mapGetters("setting", ["details"]),
  },
  beforeDestroy() {
    settingService.resetAllEditState();
  },
  mounted() {
    settingService.getDetails();
  },
};
</script>

<style lang="scss" scoped></style>
