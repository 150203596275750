var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('validation-observer',{ref:"formValidator"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-5 col-sm-6"},[_c('label-content',{attrs:{"label":"Amount of invitations when joining","format-type":"pad2","editable":_vm.editable,"rules":_vm.formGroup.amount_of_invitations_when_joining.rule,"type":"number"},model:{value:(_vm.formGroup.amount_of_invitations_when_joining.value),callback:function ($$v) {_vm.$set(_vm.formGroup.amount_of_invitations_when_joining, "value", $$v)},expression:"formGroup.amount_of_invitations_when_joining.value"}})],1),_c('div',{staticClass:"col-md-5 col-sm-6"},[_c('label-content',{attrs:{"label":"Amount of additional invitations if invite becomes customer","format-type":"pad2","editable":_vm.editable,"rules":_vm.formGroup
              .amount_of_additional_invitations_if_invite_become_customer.rule,"type":"number"},model:{value:(
            _vm.formGroup
              .amount_of_additional_invitations_if_invite_become_customer
              .value
          ),callback:function ($$v) {_vm.$set(_vm.formGroup
              .amount_of_additional_invitations_if_invite_become_customer
              , "value", $$v)},expression:"\n            formGroup\n              .amount_of_additional_invitations_if_invite_become_customer\n              .value\n          "}})],1),_c('div',{staticClass:"col-md-5 col-sm-6"},[_c('label-content',{attrs:{"label":"Max amount of shadow accounts","format-type":"pad2","editable":_vm.editable,"rules":_vm.formGroup.max_account_of_shadow_accounts.rule,"type":"digits_field"},model:{value:(_vm.formGroup.max_account_of_shadow_accounts.value),callback:function ($$v) {_vm.$set(_vm.formGroup.max_account_of_shadow_accounts, "value", $$v)},expression:"formGroup.max_account_of_shadow_accounts.value"}})],1),_c('div',{staticClass:"col-md-5 col-sm-6"},[_c('label-content',{attrs:{"label":"Invite Message","editable":_vm.editable,"rules":_vm.formGroup.invite_message.rule},model:{value:(_vm.formGroup.invite_message.value),callback:function ($$v) {_vm.$set(_vm.formGroup.invite_message, "value", $$v)},expression:"formGroup.invite_message.value"}})],1),_c('div',{staticClass:"col-md-5 col-sm-6"},[_c('label-content',{attrs:{"label":"Shadow Invite Message","editable":_vm.editable,"rules":_vm.formGroup.shadow_invite_message.rule},model:{value:(_vm.formGroup.shadow_invite_message.value),callback:function ($$v) {_vm.$set(_vm.formGroup.shadow_invite_message, "value", $$v)},expression:"formGroup.shadow_invite_message.value"}})],1)])]),_c('progress-loader',{attrs:{"show":_vm.showProgress,"no-wrap":true}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }