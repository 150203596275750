<template>
  <section>
    <div class="row">
      <div class="col-12">
        <div class="row">
          <div class="col-md-9">
            <base-editor v-model="formGroup.privacy_policy.value"></base-editor>
          </div>
        </div>
      </div>
    </div>
    <base-button
      type="primary"
      class="showButton"
      :loading="loading"
      @click="saveData"
    >
      Submit
    </base-button>

    <progress-loader :show="showProgress" :no-wrap="true"></progress-loader>
  </section>
</template>

<script>
import { FormGroup, Text } from "@/core/models";
import SettingMixin from "@/core/mixins/setting.mixin";
import { settingService, notificationService } from "@/core/services";
import { SETTING } from "@/core/enums";

export default {
  name: "Policy",
  mixins: [SettingMixin],
  props: {
    model: {
      type: Object,
      default: () => {
        return new Text();
      },
    },
  },
  data() {
    return {
      test: "",
      formGroup: new FormGroup({
        privacy_policy: [null],
      }),
      loading: false,
      settingType: SETTING.POLICY,
    };
  },
  watch: {
    model(newVal, _oldVal) {
      if (newVal) {
        this.formGroup.privacy_policy.value = newVal.privacy_policy;
      }
    },
  },
  methods: {
    saveData() {
      const form = this.formGroup.value;
      const payload = {
        type: this.settingType,
        data: { ...this.model, ...form },
      };
      this.loading = true;
      settingService
        .saveDetails(payload)
        .then((res) => {
          notificationService.success(res.message);
        })
        .finally(() => (this.loading = false));
    },
  },
};
</script>

<style lang="scss" scoped>
.showButton {
  margin-top: 45px;
}
</style>
