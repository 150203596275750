<template>
  <section>
    <progress-loader :show="faqLoaded">
      <div class="row">
        <div class="col-12">
          <div class="row">
            <div class="col-md-9">
              <div
                v-for="(questionRow, counter) in questions"
                :key="counter"
                class="question"
              >
                <validation-observer ref="formValidator" v-slot="{ invalid }">
                  <div class="question-main">
                    <div class="question-main_control">
                      <div class="question_content">
                        <div class="question_content-idx">
                          {{ counterFormatter(counter + 1) }}.
                        </div>
                        <div class="question_content-input">
                          <base-input
                            v-model="questionRow.question"
                            name="Question"
                            placeholder="Enter Question"
                            input-classes="form-control-sm"
                            :required="true"
                            :hide-error-feedback="true"
                          ></base-input>
                        </div>
                      </div>
                      <div class="question_answer">
                        <base-input :hide-error-feedback="true">
                          <textarea
                            v-model="questionRow.answer"
                            placeholder="Write Answer..."
                            class="form-control"
                            rows="3"
                            resize="none"
                            :required="true"
                            name="Question"
                          ></textarea>
                        </base-input>
                      </div>
                    </div>
                    <div class="question-main_action">
                      <!-- :loading="noteRow.busy" -->
                      <!-- @click="save(noteRow, noteRowIdx)" -->

                      <base-button
                        :loading="questionRow.busy"
                        :disabled="disabledSave(invalid)"
                        size="sm"
                        type="success"
                        @click="onSave(questionRow, counter)"
                      >
                        <span class="fas fa-save"></span>
                        Save
                      </base-button>
                      <base-button
                        v-if="!questionRow.id"
                        size="sm"
                        type="light"
                        @click="cancelQuestion(questionRow, counter)"
                      >
                        <span class="fas fa-times"></span>
                        Cancel
                      </base-button>
                      <base-button
                        v-if="questionRow.id"
                        :loading="questionRow.deleting"
                        size="sm"
                        type="danger"
                        @click="removeQuestion(questionRow, counter)"
                      >
                        <span class="fas fa-times"></span>
                        Remove
                      </base-button>
                    </div>
                  </div>
                </validation-observer>
              </div>
            </div>
          </div>
        </div>
      </div>
      <base-button size="sm" type="primary" @click="addQuestion()">
        <span class="fas fa-plus"></span>
        Add More
      </base-button>
    </progress-loader>
  </section>
</template>

<script>
import { Helper } from "@/core/utils";
import {
  confirmDialogService,
  notificationService,
  settingService,
} from "@/core/services";

export default {
  name: "Faqs",
  props: {
    model: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      questions: [],
      questionModel: {
        id: null,
        question: null,
        answer: null,
        busy: false,
        deleting: false,
      },
      faqLoaded: false,
    };
  },
  computed: {
    disabledSave() {
      return function (invalid) {
        return invalid;
      };
    },
  },
  mounted() {
    this.faqLoaded = true;
    settingService
      .getAllFAQ()
      .then((resData) => {
        this.questions = resData.map((item) => {
          const { question, answer, id } = item;
          return Object.assign({}, this.questionModel, {
            question,
            answer,
            id,
          });
        });
      })
      .finally(() => {
        this.faqLoaded = false;
      });
  },
  methods: {
    addQuestion() {
      this.questions.push(Object.assign({}, this.questionModel));
    },
    cancelQuestion(questionRow, counter) {
      if (questionRow.busy) {
        return;
      }
      this.questions.splice(counter, 1);
    },
    removeQuestion(questionRow, counter) {
      if (questionRow.busy) {
        return;
      }

      confirmDialogService.open(
        "Are you sure you want to remove this FAQ?",
        () => {
          questionRow.deleting = true;

          settingService
            .removeFAQ(questionRow.id)
            .then((_res) => {
              this.questions.splice(counter, 1);
            })
            .finally(() => (questionRow.deleting = false));
        }
      );
    },
    onSave(questionRow, _counter) {
      if (questionRow.deleting) {
        return;
      }

      const payload = {
        question: questionRow.question,
        answer: questionRow.answer,
      };

      questionRow.busy = true;

      settingService
        .saveFAQ(payload, questionRow.id)
        .then((questionRes) => {
          if (!questionRow.id) {
            questionRow.id = questionRes.data.id;
          }
          notificationService.success(questionRes.message);
        })
        .finally(() => (questionRow.busy = false));
    },
    counterFormatter(val) {
      return Helper.pad2(val);
    },
  },
};
</script>

<style lang="scss" scoped>
.question {
  margin-bottom: 1.5em;
  .question-main {
    display: flex;
    &_control {
      flex: 1 0 75%;
      background: #f0f6f7;
      border: 1px solid #e3dddd;
      border-radius: 0.35em;
      // padding: 0.75em 1em;
      .question_content {
        display: flex;
        border-bottom: 1px solid #c1bdbd;
        &-idx {
          border-right: 1px solid #c1bdbd;
          flex-basis: 6%;
          text-align: center;
          padding: 0.5em 0.5em;
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: 700;
          font-size: 0.9em;
          letter-spacing: 0.5px;
        }
        &-input {
          flex-basis: 100%;
          display: flex;
          align-items: center;
          padding: 0.5em 0.5em;
          ::v-deep {
            span {
              flex-grow: 1;
            }
            .form-group {
              margin-bottom: 0px;
              .form-control {
                height: calc(0.45em + 1.25rem + 10px);
              }
            }
          }
        }
      }
      .question_answer {
        padding: 0.8em 0.8em;
        ::v-deep {
          .form-group {
            margin-bottom: 0px;
          }
          .input-error-container {
            textarea {
              border-color: #fb6340;
            }
          }
        }
      }
    }
    &_action {
      display: flex;
      flex-direction: column;
      margin-left: 0.5em;
      button {
        margin-right: 0;
        margin-bottom: 0.5em;
      }
    }
  }
}
// span {
//   width: 30px;
//   float: right;
//   cursor: pointer;
// }
// span:hover {
//   color: brown;
// }
// .text-danger {
//   cursor: pointer;
//   text-align: left;
// }
// .previous {
//   border: 1.5px solid;
//   padding: 5px;
//   margin-bottom: 10px;
// }
</style>
