<template>
  <section>
    <div class="row">
      <div class="col-12">
        <div class="token-sec">
          <h3>Information Campaign:</h3>
          <div class="campaign">
            <div class="campaign__col">
              <div class="campaign-content">
                <div class="campaign-content__date">
                  <label for="">Information Period</label>
                  <div class="input-container">
                    <div class="input-container__control">
                      <label-content
                        v-model="formGroup.information_popup_start_date.value"
                        :editable="editable"
                        input-type="date"
                        placeholder="From Day"
                      ></label-content>
                    </div>
                    <div class="input-container__control">
                      <label-content
                        v-model="formGroup.information_popup_end_date.value"
                        :editable="editable"
                        input-type="date"
                        placeholder="To Day"
                      ></label-content>
                    </div>
                  </div>
                </div>
                <div class="campaign-content__time">
                  <label for="">Information Time</label>
                  <div class="input-container">
                    <div class="input-container__control">
                      <label-content
                        v-model="formGroup.information_popup_start_time.value"
                        :editable="editable"
                        input-type="time"
                        placeholder="From Time"
                      ></label-content>
                    </div>
                    <div class="input-container__control">
                      <label-content
                        v-model="formGroup.information_popup_end_time.value"
                        :editable="editable"
                        input-type="time"
                        placeholder="To Time"
                      ></label-content>
                      <!-- <base-datepicker
                  ref="datepicker"
                  :readonly="false"
                  mode="range"
                  v-model="formGroup.to_time.value"
                  :value="formGroup.to_time"
                  no-calendar="true"
                  date-format="H:i"
                  label="To Time"
                  placeholder="Select Date"
                ></base-datepicker> -->
                    </div>
                  </div>
                </div>

                <!-- <div class="campaign-txt campaign-txt--narrow">08.00</div> -->

                <!-- <div class="campaign-txt campaign-txt--narrow">19.30</div> -->
              </div>
            </div>
          </div>
          <!-- <div class="campaign">
        <div class="campaign__col">
          <div class="campaign-content">
            <div class="campaign-txt">0.5</div>
            <span class="campaign-txt-val">Token</span>
          </div>
        </div>
      </div> -->
        </div>
        <div class="row">
          <div class="col-md-9">
            <base-editor
              v-model="formGroup.information_popup_text.value"
            ></base-editor>
          </div>
        </div>
      </div>
    </div>
    <base-button
      type="primary"
      class="showButton"
      :loading="loading"
      @click="saveData"
    >
      Submit
    </base-button>

    <progress-loader :show="showProgress" :no-wrap="true"></progress-loader>
  </section>
</template>

<script>
import { FormGroup, InformationPopup } from "@/core/models";
import SettingMixin from "@/core/mixins/setting.mixin";
import { settingService, notificationService } from "@/core/services";
import { SETTING } from "@/core/enums";
import LabelContent from "@/views/common/LabelContent.vue";
import { DateTime } from "@/core/utils";

export default {
  name: "Information",
  components: { LabelContent },
  mixins: [SettingMixin],
  props: {
    model: {
      type: Object,
      default: () => {
        return new InformationPopup();
      },
    },
  },
  data() {
    return {
      test: "",
      formGroup: new FormGroup({
        information_popup_text: [null],
        information_popup_start_date: [null],
        information_popup_end_date: [null],
        information_popup_start_time: [null],
        information_popup_end_time: [null],
      }),
      loading: false,
      settingType: SETTING.INFORMATIONPOPUP,
      campaignDuration: null,
    };
  },
  campaignDate: {
    get() {
      /*  console.log(this.formGroup.campaign_end_date);
        if (
          this.formGroup.campaign_end_date.value &&
          this.formGroup.campaign_start_date.value
        ) {
          return [
            this.formGroup.campaign_start_date.value,
            this.formGroup.campaign_end_date.value,
          ];
        } */
      return this.campaignDuration;
    },
    set(value) {
      this.campaignDuration = value;
      console.log(this.formGroup.campaign_start_date.value);
      if (value && value.length) {
        this.formGroup.information_popup_start_date.value = value[0];
        this.formGroup.information_popup_end_date.value = value[1];
      }

      this.$emit("input", value);
    },
  },
  computed: {
    editable() {
      return settingService.isEditable(SETTING.INFORMATIONPOPUP);
    },
  },
  watch: {
    model: {
      immediate: true,
      handler(newVal, _oldVal) {
        console.log(newVal);
      },
    },
  },
  methods: {
    saveData() {
      const form = this.formGroup.value;

      form.information_popup_start_date = DateTime.utc(
        form.information_popup_start_date,
        "YYYY-MM-DD"
      );
      form.information_popup_end_date = DateTime.utc(
        form.information_popup_end_date,
        "YYYY-MM-DD"
      );
      form.information_popup_start_time = DateTime.utc(
        form.information_popup_start_time,
        "HH:mm"
      );
      form.information_popup_end_time = DateTime.utc(
        form.information_popup_end_time,
        " HH:mm"
      );

      const payload = {
        type: this.settingType,
        data: { ...this.model, ...form },
      };

      this.loading = true;
      settingService
        .saveInfoPopup(payload)
        .then((res) => {
          notificationService.success(res.message);
        })
        .finally(() => (this.loading = false));
    },
  },
};
</script>

<style lang="scss" scoped>
.showButton {
  margin-top: 45px;
}
::v-deep {
  .token-sec {
    margin-bottom: 1.5em;
    h3 {
      border-bottom: 1px solid #dfdfdf;
      font-weight: 700;
      text-transform: capitalize;
      font-size: 0.98em;
      margin-bottom: 1em;
    }
    .refund-policy {
      display: flex;
      font-size: 0.865em;
      margin-bottom: 0.5em;
      &-field {
        padding-right: 4em;
        &:nth-last-of-type(1) {
          padding-right: 0;
        }
        .type {
          padding-right: 0.5em;
        }
      }
      &__hrs {
        flex-basis: 18%;
      }
      &__cutoff {
        flex-basis: 18%;
      }
      &__apply {
        display: flex;
        padding-left: 3em;
        .apply-sec {
          display: flex;
          align-items: center;
          padding-right: 0.8em;
          span {
            font-weight: 600;
          }
          i {
            padding-left: 5px;
            font-size: 1.1em;
          }
        }
      }
    }
    .campaign {
      display: flex;
      font-size: 0.865em;
      margin-bottom: 0.5em;
      &__col {
        width: 100%;
        padding-right: 3em;
        &:nth-last-of-type(1) {
          padding-right: 0;
        }
        .campaign-content {
          display: flex;
          margin-top: 1em;
          .label-content {
            margin-bottom: 0;
          }

          .control {
            width: 100%;
          }
          &__date {
            flex-basis: 30%;
            display: flex;
            flex-direction: column;
            margin-right: 1em;
            .input-container {
              display: flex;
              &__control {
                width: 100%;
                .label-content__value {
                  max-width: 95%;
                  flex-basis: 100%;
                }
              }
            }
          }
          &__time {
            display: flex;
            flex-direction: column;
            margin-right: 1em;
            .input-container {
              display: flex;

              &__control {
                flex-basis: 20%;
                margin-right: 0.5em;
                .label-content__value {
                  max-width: 100%;
                  flex-basis: 100%;
                }
              }
            }
          }
          .campaign-txt {
            padding-right: 1em;
            padding-left: 1em;
            &--narrow {
              padding-right: 0.6em;
              padding-left: 0.6em;
            }
            &:nth-of-type(1) {
              padding-left: 0;
            }
            &:nth-last-of-type(1) {
              padding-right: 0;
            }
          }
          .campaign-txt-val {
            padding-left: 0.5em !important;
          }
          .divider {
            padding: 0 0.2em;
          }
          .dash {
            &::after {
              display: block;
              text-align: center;
              font-size: 0.7em;
              content: "—";
              position: relative;
              top: 3px;
            }
          }
        }
      }
    }
  }
}
</style>
