<template>
  <div class="title-block">
    <span class="title-block__text">{{ title }}</span>
    <span class="title-block__action">
      <slot v-if="editoption !== 'false'">
        <i
          v-if="!editable"
          v-b-tooltip
          title="Edit"
          class="fas fa-pencil-alt text-primary"
          @click="toggleEdit()"
        ></i>
        <i
          v-if="editable"
          v-b-tooltip
          title="Cancel"
          class="fas fa-times text-danger"
          @click="cancel()"
        ></i>
        <i
          v-if="editable"
          v-b-tooltip
          title="Save"
          class="fas fa-save text-success"
          @click="save()"
        ></i>
      </slot>
    </span>
  </div>
</template>

<script>
import { eventService, settingService } from "@/core/services";
import { SETTING_EVENT } from "@/core/enums";

export default {
  props: {
    title: {
      type: String,
      default: "",
    },
    editoption: {
      type: String,
      default: "",
    },
    settingType: {
      type: String,
      default: "",
    },
    mode: {
      type: String,
      default: "",
    },
  },
  data() {
    return {};
  },
  computed: {
    //...mapGetters("setting", ["isEditable"]),
    editable() {
      return settingService.isEditable(this.settingType);
    },
  },
  methods: {
    toggleEdit() {
      const isEditable = !this.editable;
      settingService.toggleEdit(this.settingType, isEditable);
    },
    cancel() {
      this.toggleEdit();
      eventService.emit(SETTING_EVENT[this.mode].CANCEL);
    },
    save() {
      eventService.emit(SETTING_EVENT[this.mode].SAVE);
    },
  },
};
</script>

<style lang="scss" scoped>
.title-block {
  display: inline-block;
  &__text {
  }
  &__action {
    margin-left: 1em;
    i {
      padding: 0 0.22em;
      font-size: 0.865em;
      cursor: pointer;
    }
  }
}
</style>
