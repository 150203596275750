<template>
  <div class="row label-content">
    <div class="label-content__title col-md-12 col-sm-12">{{ label }}</div>
    <div v-if="!editable" class="label-content__value col-md-12 col-sm-12">
      <span>
        {{ formattedValue || "&#8211;" }}
      </span>
      <span
        v-if="
          model &&
          suffix &&
          (!formatType || ['decimal', 'number'].indexOf(formatType) != -1)
        "
      >
        {{ suffix }}
      </span>
    </div>
    <div v-else class="label-content__value col-md-8">
      <validation-provider :rules="rules">
        <span v-if="inputType == 'date'">
          <base-datepicker
            v-model="model"
            :readonly="false"
            date-format="Y-m-d"
            enable-time="false"
            :placeholder="label ? label : placeholder"
          ></base-datepicker>
        </span>

        <span v-else-if="inputType == 'time'">
          <base-datepicker
            v-model="model"
            :readonly="false"
            mode="range"
            :enable-hrs="true"
            no-calendar="true"
            date-format="H:i"
            :placeholder="label ? label : placeholder"
          ></base-datepicker>
        </span>
        <span v-else>
          <base-input
            v-model="model"
            :placeholder="label"
            input-classes="form-control-sm"
            :name="label"
            :rules="rules"
            :type="type"
            mode="eager"
          ></base-input>
        </span>
      </validation-provider>
    </div>
  </div>
</template>

<script>
import { Helper } from "@/core/utils";

export default {
  name: "LabelContent",
  props: {
    label: {
      type: String,
      default: "",
    },
    text: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: String,
    },
    suffix: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    rules: {
      type: [String, Array, Object],
      description: "Vee validate validation rules",
      default: "",
    },
    value: undefined,
    altValue: {
      type: Array,
      default: () => {
        return [];
      },
    },
    editable: {
      type: Boolean,
      default: false,
    },
    inputType: {
      type: String,
      default: "",
      description: "date | time | date-time ",
    },
    formatType: {
      type: String,
      default: "",
      description: "price | percent | number | decimal | boolean",
    },
  },
  data() {
    return {};
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    formattedValue() {
      if (
        ["price", "percent", "decimal", "number"].indexOf(this.formatType) != -1
      ) {
        switch (this.formatType) {
          case "decimal":
            return Helper.numberFormat(this.value);
          case "price":
            return Helper.numberFormat(this.value) + " €";
          case "percent":
            return Helper.numberFormat(this.value) + " %";
          default:
            return Helper.numberFormat(this.value, 0);
        }
      } else if (this.formatType == "pad2") {
        return this.value ? Helper.pad2(this.value) : "";
      } else if (this.formatType == "boolean") {
        return this.value ? "Yes" : "No";
      }
      return this.value;
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.label-content {
  margin-bottom: 2em;
  &__title {
    font-size: 0.88em;
    font-weight: 600;
    margin-bottom: 0.4em;
  }
  &__value {
    font-size: 0.865em;
  }
}
</style>
