<template>
  <section>
    <validation-observer ref="formValidator">
      <div class="row">
        <div class="col-md-5 col-sm-6">
          <label-content
            v-model="formGroup.time_between_bookings.value"
            label="Time between bookings"
            suffix="min"
            :editable="editable"
            :rules="formGroup.time_between_bookings.rule"
            type="number"
          ></label-content>
        </div>
        <div class="col-md-5 col-sm-6">
          <label-content
            v-model="formGroup.time_after_immediate_take_off.value"
            label="Time after immediate takeoff"
            suffix="min"
            :editable="editable"
            :rules="formGroup.time_after_immediate_take_off.rule"
            type="number"
          ></label-content>
        </div>
        <div class="col-md-5 col-sm-6">
          <label-content
            v-model="formGroup.max_duration_immediate_take_off.value"
            label="Max duration immediate takeoff"
            suffix="min"
            :editable="editable"
            :rules="formGroup.max_duration_immediate_take_off.rule"
            type="number"
          ></label-content>
        </div>
        <div class="col-md-5 col-sm-6">
          <label-content
            v-model="formGroup.default_immediate_take_off_boarding_time.value"
            label="Default immediate take of boarding time"
            suffix="min"
            :editable="editable"
            :rules="formGroup.default_immediate_take_off_boarding_time.rule"
            type="number"
          ></label-content>
        </div>
        <div class="col-md-5 col-sm-6">
          <label-content
            v-model="formGroup.plane_rest_time.value"
            label="Turnaround time"
            suffix="min"
            :editable="editable"
            :rules="formGroup.plane_rest_time.rule"
            type="number"
          ></label-content>
        </div>
      </div>
    </validation-observer>
    <progress-loader :show="showProgress" :no-wrap="true"></progress-loader>
  </section>
</template>

<script>
import { settingService, notificationService } from "@/core/services";

import { BookingMargin, FormGroup } from "@/core/models";
import LabelContent from "@/views/common/LabelContent.vue";
import { SETTING, SETTING_EVENT } from "@/core/enums";
import SettingMixin from "@/core/mixins/setting.mixin";

export default {
  name: "BookingMargin",
  components: { LabelContent },
  mixins: [SettingMixin],
  props: {
    model: {
      type: Object,
      default: () => {
        return new BookingMargin();
      },
    },
  },
  data() {
    return {
      formGroup: new FormGroup({
        time_between_bookings: [
          this.model.time_between_bookings,
          { required: true },
        ],
        time_after_immediate_take_off: [
          this.model.time_after_immediate_take_off,
          { required: true },
        ],
        max_duration_immediate_take_off: [
          this.model.max_duration_immediate_take_off,
          { required: true },
        ],
        default_immediate_take_off_boarding_time: [
          this.model.default_immediate_take_off_boarding_time,
          { required: true },
        ],
        plane_rest_time: [this.model.plane_rest_time, { required: true }],
      }),
      eventType: SETTING_EVENT.BOOKING,
      settingType: SETTING.BOOKING,
    };
  },
  computed: {
    editable() {
      return settingService.isEditable(SETTING.BOOKING);
    },
  },
  methods: {
    async save() {
      const validate = await this.$refs.formValidator.validate();
      if (validate) {
        const form = this.formGroup.value;
        const payload = { type: this.settingType, data: form };

        this.toggleProgress();
        settingService.saveDetails(payload).finally(() => {
          notificationService.success("Settings has been updated successfully");
          this.toggleProgress();
          this.closeEdit();
        });
      }
    },
  },
};
</script>
