<template>
  <section class="setting-uploader">
    <div class="row justify-content-center">
      <div class="col-md-12 col-sm-12 doc-upload-sec">
        <multi-upload
          :selected-docs="selectedSafetyDocs"
          label="Safety Card"
          :hide-remove="true"
          :upload-callback="upload"
        ></multi-upload>
      </div>
      <!-- <div class="col-md-6 col-sm-12 doc-upload-sec">
        <file-upload label="FAQ"></file-upload>
      </div>
      <div class="col-md-6 col-sm-12 doc-upload-sec">
        <file-upload label="Terms & Condition"></file-upload>
      </div>
      <div class="col-md-6 col-sm-12 doc-upload-sec">
        <file-upload label="Privacy Policy"></file-upload>
      </div> -->
    </div>
  </section>
</template>

<script>
import { settingService } from "@/core/services";
import { Other } from "@/core/models";
import SettingMixin from "@/core/mixins/setting.mixin";
import MultiUpload from "@views/common/MultiUpload.vue";
import { Helper } from "@/core/utils";

export default {
  name: "DocUpload",
  components: {
    MultiUpload,
  },
  mixins: [SettingMixin],
  props: {
    model: {
      type: Object,
      default: () => {
        return new Other();
      },
    },
  },
  data() {
    return {
      doc: {
        safetyCard: {
          busy: false,
        },
      },
    };
  },
  computed: {
    selectedSafetyDocs() {
      if (this.model.uploads.safety_cards) {
        let parsedData;
        if (typeof this.model.uploads.safety_cards == "string") {
          parsedData = JSON.parse(this.model.uploads.safety_cards);
        } else {
          parsedData = this.model.uploads.safety_cards;
        }
        return parsedData.map((item) => {
          return {
            file_name: item,
            original_name: Helper.fileNameFromUrl(item),
            id: Helper.uid(),
          };
        });
      }
      return [];
    },
  },
  methods: {
    upload(file, progressCallback) {
      return settingService.uploadDoc(file, "safety_cards", progressCallback);
    },
  },
};
</script>

<style scoped lang="scss">
.setting-uploader {
  .doc-upload-sec {
    margin-bottom: 1em;
  }
}
</style>
